import React, { useState } from "react";
import "./header.scss";
import { Link } from "react-router-dom";

export function Header() {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <div className="header-top">
        <div className="container">
          <p>Telefon: </p>
          <div className="phoneList">
            <span>+90 542 563 08 88</span>
          </div>
        </div>
        <div className="email">
          <p>Adres: </p>
          <span>Yayla, Kale Cami Sk. No:3, 34270 Sultangazi/İstanbul</span>
        </div>
      </div>
      <header className="header">
        <div className="headerContainer">
          <div className="logo">
            <Link to="/">
              <img src="/yayla-course/yaylakuran.png" />
            </Link>
          </div>
          <nav className="menu">
            <ul>
              <li>
                <Link to="/">ANASAYFA</Link>
              </li>
              <li>
                <Link to="/about">HAKKIMIZDA</Link>
              </li>
              <li>
                <Link to="/hafizinolsun">SENİNDE BİR HAFIZIN OLSUN</Link>
              </li>
              <li>
                <Link to="/contact">İLETİŞİM</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`line ${menuOpen ? "open" : ""}`}></div>
          <div className={`line ${menuOpen ? "open" : ""}`}></div>
          <div className={`line ${menuOpen ? "open" : ""}`}></div>
        </div>
      </header>
      <div>
        {!menuOpen ? (
          <nav className={`menuMobile`}>
            <ul>
              <Link to="/">
                <li onClick={toggleMenu}>ANASAYFA</li>
              </Link>
              <Link to="/about">
                <li onClick={toggleMenu}>HAKKIMIZDA</li>
              </Link>
              <Link to="/hafizinolsun">
                <li onClick={toggleMenu}>SENİNDE BİR HAFIZIN OLSUN</li>
              </Link>

              <Link to="/contact">
                <li onClick={toggleMenu}>
                  <a>İLETİŞİM</a>
                </li>
              </Link>
            </ul>
          </nav>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Header;
