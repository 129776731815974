import styles from "../../styles/yayla-course/MedreseInfo.module.css";

import React, { useEffect, useState } from "react";

export const MedreseInfo = () => {
  return (
    <section className={styles.mainInfo}>
      <div className={styles.header}>
        <span>Kız Hafızlık Medresesi</span>
        <p>
          Sultan Fatihi Sultan Selimi dünyaya getiren annelerimiz şüphesiz Kuran
          aşığı kişilerdi
        </p>
      </div>

      <div className={styles.header}>
        <span>Kız Arapça Medresesi</span>
        <p>
          İslamiyet kadar büyük devlet yoktur. Kıymetini bilelin. Çocuklarımızı
          Kuran ehli olarak yetiştirelim .
        </p>
      </div>

      <div className={styles.header}>
        <span>Kız Gündüzcü Medresesi</span>
        <p>
          Kız çocuklarımıza öğleden önce ve sonra derneğinimizde ders imkanı
          sunuyoruz.
        </p>
      </div>

      <div className={styles.header}>
        <span>Hanımlık Dernek Faliyeti </span>
        <p>
          Derneğimizde öğleden önce 10:00 - 13:00 arası ve 12:00 - 17:00 arası
          sınıflar açılmıştır. Hanımlardan 7 den 70 e herkes İlmihal, Kuran ı
          Kerim, Elif-Ba derslerinden ve Akaid derslerinden istifade edebilir.
        </p>
      </div>

      <div className={styles.header}>
        <span>Hanımlık Haftalık Sohbetler </span>
        <p>
          Toplumun bozulmakta sınır tanımadığı çağımızda her perşembe günü öğle
          namazına müteakiben muhtelif hoca hanımların teşrifleriyle ailede
          hanımların, çocuk eğitimi, eşlerin birbiri üzerindeki hakkı gibi
          konular ağırlıklık vaazu nasihatlerle kendimizi ve çevremizi bu
          ateşten korumaya çalışıyoruz.
        </p>
      </div>
    </section>
  );
};

export default MedreseInfo;
