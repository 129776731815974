import React, { Suspense } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { ScrollToTopOnPageChange } from "../scroll/Scroll";
import Footer from "../yayla-course/Footer";
import SeventhSection from "../yayla-course/SeventhSection";
import { Helmet } from "react-helmet";

export default function Layout() {
  return (
    <>
      <Header />
      <Helmet>
        <title>Yayla Kuran Kursu </title>
        <meta name="description" content="Yayla Kuran Kursu, " />
        <meta
          name="keywords"
          content="Yayla Kuran Kursu, Yayla, Yayla Kuran, Kuran Kursu, yayla"
        />
      </Helmet>
      <main>
        <Suspense>
          <ScrollToTopOnPageChange />
          <Outlet />
        </Suspense>
      </main>
      <SeventhSection />
      <Footer />
    </>
  );
}
