import styles from "../../styles/yayla-course/Header.module.css";

import React, { useEffect, useRef, useState } from "react";

export const SeventhSection = () => {
  const [isMobile, setIsMobile] = useState(null);
  const [screenSize, setScreenSize] = useState({
    width: "0",
    height: "",
  });

  return (
    <div>
      <div className={styles.contact}>
        <p>İletişim ve Bağış</p>
        <span>
          <strong>Adres: </strong> Yayla, Kale Cami Sk. No:3, 34270
          Sultangazi/İstanbul
        </span>
        <span>
          <strong>Telefon:</strong> +90 542 563 08 88
        </span>
      </div>
      {!isMobile ? (
        <section className={styles.seventhSection}>
          <img src="/yayla-course/seventh1.jpg" alt="Logo" />
          <img src="/yayla-course/seventh2.jpg" alt="Logo" />
          <img src="/yayla-course/seventh3.jpg" alt="Logo" />
          <img src="/yayla-course/seventh4.jpg" alt="Logo" />
        </section>
      ) : (
        <section className={styles.seventhSection}>
          <img src="/yayla-course/seventh4.jpg" alt="Logo" />
        </section>
      )}
    </div>
  );
};

export default SeventhSection;
