import styles from "../../styles/yayla-course/MainPrice.module.css";

import React from "react";

export const MainPrice = () => {
  const priceStyle: React.CSSProperties = {
    color: "black",
    fontSize: "18px",
    fontWeight: "bold",
  };
  const currencyStyle: React.CSSProperties = {
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
  };
  const cardPStyles: React.CSSProperties = {
    marginBottom: "57px",
  };
  return (
    <section className={styles.mainPrice}>
      <div className={styles.info}>
        <span> SENİNDE BİR HAFIZIN OLSUN...</span>
        <p>
          Öldüğünde arkandan Amel defterinin açık kalıp hayırlarının yazılmasına
          devam edecek bir talebe okutabilirsin. O hafızın hayatı boyunca
          yaptığı hatimlere ve diğer ibadetlere ortak olabilirsin.{" "}
        </p>
        <img src="/yayla-course/main-price.jpeg" alt="Logo" />
        <p className={styles.infoTitle}>
          Peygamber Efendimiz (sav) buyurdular ki;
        </p>
        <p>
          {" "}
          Yarım hurma ile de olsa ateşten korunun bunu da bulamazsanız; Gönül
          alıcı güzel sözler söyleyin{" "}
        </p>
      </div>

      <div className={styles.infoCardList}>
        <div className={styles.infoCard}>
          <p>AKAK</p>
          <p>AKİKA</p>
          <p>KURBAN</p>
          <span style={priceStyle}>8000</span>{" "}
          <span style={currencyStyle}>TL</span>
        </div>
        <div className={styles.infoCard}>
          <p>FATURA</p>
          <p>DESTEK</p>
          <p style={cardPStyles}></p>
          <span style={priceStyle}>2000</span>{" "}
          <span style={currencyStyle}>TL</span>
        </div>
        <div className={styles.infoCard}>
          <p>BİR</p>
          <p>KİŞİLİK</p>
          <p>İFTAR</p>
          <span style={priceStyle}>150</span>{" "}
          <span style={currencyStyle}>TL</span>
        </div>
        <div className={styles.infoCard}>
          <p>KAHVALTILIK</p>
          <p>GIDA</p>
          <p>KOLİ</p>
          <span style={priceStyle}>1000</span>{" "}
          <span style={currencyStyle}>TL</span>
        </div>
        <div className={styles.infoCard}>
          <p>HAFIZLIK</p>
          <p>BURSU</p>
          <p style={cardPStyles}></p>
          <span style={priceStyle}>1000</span>{" "}
          <span style={currencyStyle}>TL</span>
        </div>
      </div>
      <div className={styles.iban}>
        <span>Yayla Mizan Derneği</span>
        <p>TR36 0021 1000 0006 7432 5000 01</p>
      </div>
    </section>
  );
};

export default MainPrice;
