import Hafiz from "../components/yayla-course/Hafiz";
import MainArabicMadrasa from "../components/yayla-course/MainArabicMadrasa";
import MainPrice from "../components/yayla-course/MainPrice";
import MainIfo from "../components/yayla-course/MainInfo";
import MedreseInfo from "../components/yayla-course/MedreseInfo";

export const HafizinOlsun = () => {
  return (
    <>
      <MainPrice />
      <MedreseInfo />
    </>
  );
};

export default HafizinOlsun;
