export const PathConstants = {
  HOME: "/",
  ABOUT: "about",
  REFERENCES: "references",
  CONTACT: `contact`,
  SERVICES: "services",
  HAFIZINOLSUN: "hafizinolsun",
};

export default PathConstants;
