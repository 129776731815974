import React from "react";
import PathConstants from "./pathConstants";
import HafizinOlsun from "../pages/HafizinOlsun";
import Contact from "../pages/Contact";

const Home = React.lazy(() => import("../pages/Home"));
const About = React.lazy(() => import("../pages/About"));

export const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.ABOUT, element: <About /> },
  { path: PathConstants.HAFIZINOLSUN, element: <HafizinOlsun /> },
  { path: PathConstants.CONTACT, element: <Contact /> },
];

export default routes;
