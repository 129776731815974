import React, { useEffect, useRef, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import styles from "../../styles/yayla-course/Header.module.css";

export const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }

    // Component ilk yüklendiğinde ve pencere boyutu değiştiğinde handleResize çağrılır
    window.addEventListener("resize", handleResize);
    handleResize(); // Component ilk render edildiğinde ekran boyutunu kontrol etmek için

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      {!isMobile ? (
        <section className={styles.sixthSection}>
          <p>Sosyal Medya Hesaplarımız</p>
          <div className={styles.iconList}>
            <a href="https://www.facebook.com/share/G8XcGBwjD5qDsdaD/?mibextid=qi2Omg">
              <FaFacebook size={60} color="#938675" />
            </a>
            <a href="https://twitter.com/Mustafa17492737?t=dnaxkA1j5MlR004kowRGcA&s=08">
              <FaTwitter size={60} color="#938675" />
            </a>
            <a href="https://www.instagram.com/yayla_medresesi?igsh=NTc4MTIwNjQ2YQ==">
              <FaInstagram size={60} color="#938675" />
            </a>
          </div>
        </section>
      ) : (
        <section className={styles.sixthSection}>
          <p>Sosyal Medya Hesaplarımız</p>
          <div className={styles.iconList}>
            <a href="https://www.facebook.com/share/G8XcGBwjD5qDsdaD/?mibextid=qi2Omg">
              <FaFacebook size={30} color="#938675" />
            </a>
            <a href="https://twitter.com/Mustafa17492737?t=dnaxkA1j5MlR004kowRGcA&s=08">
              <FaTwitter size={30} color="#938675" />
            </a>
            <a href="https://www.instagram.com/yayla_medresesi?igsh=NTc4MTIwNjQ2YQ==">
              <FaInstagram size={30} color="#938675" />
            </a>
          </div>
        </section>
      )}
    </div>
  );
};

export default Footer;
